// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activation-index-js": () => import("./../src/pages/activation/index.js" /* webpackChunkName: "component---src-pages-activation-index-js" */),
  "component---src-pages-app-index-js": () => import("./../src/pages/app/index.js" /* webpackChunkName: "component---src-pages-app-index-js" */),
  "component---src-pages-approvals-benefit-approvals-js": () => import("./../src/pages/Approvals/BenefitApprovals.js" /* webpackChunkName: "component---src-pages-approvals-benefit-approvals-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-forgotten-password-js": () => import("./../src/pages/forgotten-password.js" /* webpackChunkName: "component---src-pages-forgotten-password-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-password-reset-index-js": () => import("./../src/pages/password-reset/index.js" /* webpackChunkName: "component---src-pages-password-reset-index-js" */),
  "component---src-pages-privacy-statement-js": () => import("./../src/pages/privacy-statement.js" /* webpackChunkName: "component---src-pages-privacy-statement-js" */),
  "component---src-pages-sso-error-js": () => import("./../src/pages/sso-error.js" /* webpackChunkName: "component---src-pages-sso-error-js" */),
  "component---src-pages-sso-login-login-js": () => import("./../src/pages/sso-login/login.js" /* webpackChunkName: "component---src-pages-sso-login-login-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

