/* eslint-disable no-console */
import fetchApi from '@/utils/fetchApi'
import * as restAPI from '@/utils/endpoints'
import { transformTermsArrayToString } from '@/utils'

const initialState = {
	terms: false,
	policy: false,
	cookie: false,
	status: false
}

export default {
	state: initialState,
	reducers: {
		RESET() {
			return initialState
		},

		SET_CONTENT(state, payload) {
			const { type, content } = payload
			return {
				...state,
				[type]: content
			}
		},

		SET_STATUS(state, payload) {
			return {
				...state,
				status: payload
			}
		}
	},

	effects: dispatch => ({
		async REQUEST_CONTENT({ url, type }) {
			const response = await fetchApi(url)

			const { error } = response

			if (error) {
				dispatch({ type: 'content/SET_STATUS', payload: 'error' })
			} else {
				dispatch({
					type: 'content/SET_CONTENT',
					payload: {
						type,
						content: transformTermsArrayToString(response)
					}
				})

				dispatch({ type: 'content/SET_STATUS', payload: 'success' })
			}
		},

		async REQUEST_ALL_CONTENT() {
			const [terms, policy, cookie] = await Promise.all([
				fetchApi(restAPI.GET_TERMS),
				fetchApi(restAPI.GET_POLICY),
				fetchApi(restAPI.GET_COOKIE_POLICY)
			])

			const error =
				terms.status === 'error' ||
				policy.status === 'error' ||
				cookie.status === 'error' ||
				terms.error ||
				policy.error ||
				cookie.error

			if (error) {
				dispatch({ type: 'content/SET_STATUS', payload: 'error' })
			} else {
				dispatch({
					type: 'content/SET_CONTENT',
					payload: {
						type: 'terms',
						content: transformTermsArrayToString(terms)
					}
				})

				dispatch({
					type: 'content/SET_CONTENT',
					payload: {
						type: 'policy',
						content: transformTermsArrayToString(policy)
					}
				})

				dispatch({ type: 'content/SET_STATUS', payload: 'success' })
			}
		}
	})
}
