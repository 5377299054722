const fetchIntercept = typeof window !== `undefined` ? require('fetch-intercept') : null

export default function logoutIntercept(store) {

	if (fetchIntercept) {
		fetchIntercept.register({
			response(response) {

				if (response.status === 401 && store.getState().user.loggedIn) {

					response.json().then(resp => {
						store.dispatch({
							type: 'user/LOGOUT',
							payload: resp.detail
						});
					})
				}

				return response;
			}
		});
	}
}
