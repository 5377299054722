// fetch polyfill.  This works nicely with gatsby and jest-fetch-mock
import 'cross-fetch/polyfill'
// ie11 polyfills
import 'core-js/modules/es6.array.from'
import 'core-js/modules/es6.promise'
// Polyfill abort controller
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import 'svg-classlist-polyfill'
import NProgress from 'nprogress'

// wrap the root element, this is used to ensure each page
// gets the correct store reference
export { default as wrapRootElement } from './scripts/wrapRootElement'

// set progress bar component
NProgress.configure({ showSpinner: false })
// gatsby life cycle hook
export const onRouteUpdateDelayed = () => {
	NProgress.start()
}
// gatsby life cycle hook
export const onRouteUpdate = () => {
	NProgress.done()
}
