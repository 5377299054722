/* eslint-disable no-console, compat/compat */
// API_URL is defined in the .env file
import * as R from 'ramda'
// eslint-disable-next-line import/no-cycle
import { getAPIBaseURL } from "./endpoints";

const MASQUERADE_HEADER = "X-Masquerade";

const allowedResponseStatus = statusCode =>
{
	const allowedStatusCodes = [ 200, 201 ]

	return allowedStatusCodes.includes(statusCode)
}

async function handleErrors(response)
{
	if (allowedResponseStatus(response.status))
	{
		const masqueradeUserId = response.headers.get(MASQUERADE_HEADER)

		const resp = await response.json()

		if(masqueradeUserId)
			resp.masqueradeUserId = masqueradeUserId

		return resp
	}

	const resp = await response.json()

	const { message, error, errors, ...extraData } = resp

	const errorMessage = new Error(JSON.stringify(errors) || error || message)

	if(extraData)
		return { error: errorMessage.message, ...extraData }

	return Promise.reject(errorMessage.message)
}

/**
 * Generates an API URL by adding a trailing slash to the baseURL if it doesn't exist
 * and removing a leading slash from the endpoint if it does exist
 */
export function generateAPIURL(baseUrl, endpoint) {
	return baseUrl.replace(/\/?$/, '/') + endpoint.replace(/^\//, '');
}

export default async function fetchApi(endpoint, options = { method: 'GET' }) {

	const response = await fetch(generateAPIURL(getAPIBaseURL(endpoint), endpoint), {
		credentials: 'include',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		...options
	})
		.then(handleErrors)
		.catch(error => {
			return { error }
		})

	// update the session time after each call to the api
	sessionStorage.setItem(`__SESSION__TIME__`, JSON.stringify(Date.now()))

	return response
}
