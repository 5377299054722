import React from 'react'
// import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { init } from '@rematch/core'
import createRematchPersist from '@rematch/persist'
import createRematchLoading from '@rematch/loading/dist/rematch-loading.esm'
import storage from 'redux-persist/lib/storage'
import * as models from '@/store'
import authenticationIntercept from "../src/store/AuthenticationInterceptor";
import logoutIntercept from "../src/store/LogoutInterceptor";

const persistPlugin = createRematchPersist({
	throttle: 500,
	version: 2,
	whitelist: ['cookie'],
	storage,
	key: 'pes'
})

const loadingPlugin = createRematchLoading({})

const store = init({
	models,
	plugins: [persistPlugin, loadingPlugin],
	redux: {
		rootReducers: {
				RESET_APP: () => undefined,
		}
	}
})

authenticationIntercept(store);
logoutIntercept(store);

// eslint-disable-next-line react/prop-types
export default ({ element }) => {

	return <Provider store={store}>{element}</Provider>
}
