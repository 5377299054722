/**
 * @function px2rem
 * @param {Number} value - the value to convert, as a unitless number
 * @return {String}
 */
export const px2rem = value =>
	value === 0
		? 0
		: typeof value !== 'undefined'
		? `${value / 16}rem`
		: undefined

/**
 * @function isLast
 * @param {number} index - the current index
 * @param {number} count - the total number of items
 * @return {boolean}
 */
export const isLast = (index, count) => index === count

/**
 * @function isNotLast
 * @param {number} index - the current index
 * @param {number} count - the total number of items
 * @return {boolean}
 */
export const isNotLast = (index, count) => !isLast(index, count)

export function prefixWithZero(value) {
	if (typeof value === 'undefined') return value

	if (value.toString().charAt(0) === '0' || !value) return value

	return value < 10 ? `0${value}` : `${value}`
}

export function suffixValueZero(value) {
	if (value.toString().charAt(0) === '0' || !value) return value

	return value < 10 ? `0${value}` : `${value}`
}

export function getPrevNext(type = 'prev', arrLength, current) {
	if (type === 'prev') {
		return current === 0 ? arrLength - 1 : current - 1
	}

	return (current + 1) % arrLength
}

export function debounce(func, wait = 250, immediate) {
	let timeout
	return function(...args) {
		const context = this
		const later = function() {
			timeout = null
			if (!immediate) func.apply(context, args)
		}
		const callNow = immediate && !timeout
		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
		if (callNow) func.apply(context, args)
	}
}

export const kebabify = string => string.replace(/\s+/g, '-').toLowerCase()

export function formatList(input, word = 'and') {
	return input
		.reduce((acc, str, index, arr) => {
			const prefix =
				index === 0 ? '' : index === arr.length - 1 ? ` ${word}` : ','
			return `${acc}${prefix} ${str}`
		}, '')
		.trim()
}

/**
 * Remove slashes from the start and end of a string
 * @param {string} str
 * @return {string}
 */
export function stripSlashes(str) {
	return str.replace(/(^\/+|\/+$)/g, '')
}

/**
 * @description split an array into chunks
 * @param {number} value
 * @param {array} data
 * @return {array}
 */
export function chunk(value, data) {
	return data.reduce((acc, item, index) => {
		if (index % value === 0) {
			const group = data.slice(index, value + index)
			return [...acc, group]
		}
		return acc
	}, [])
}

/**
 * @description get the min/max values from an array of nested objects
 * @param {array} data
 * @param {string} key
 * @return {array}
 */
export function getMinAndMaxValues(data, key) {
	const values = data.map(item => item[key])
	const min = Math.min(...values)
	const max = Math.max(...values)
	return [min, max]
}

/**
 * @descrition create an array of years
 * @param {number} from
 * @param {number} to
 * @return {array}
 */
export function createListOfYears(from, to) {
	return Array.from({ length: to - from + 1 }, (_, i) => {
		return from + i
	})
}

export const isBrowser = typeof window !== 'undefined'

export const transformTermsArrayToString = data =>
	data.reduce((acc, row) => {
		const heading = row.name ? `<h2>${row.name}</h2>` : ''
		return `${acc}${heading}${row.content}`.trim()
	}, '')
